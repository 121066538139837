<template>
  <v-col class="pa-0 d-duplication-divider">
    <v-divider />
    <v-divider />
  </v-col>
</template>

<script>

export default {
  name: 'FormDividerDuplication'
}
</script>
